<template>
  <!-- 회원 정보 수정 -->
  <coach-my-page-content-layout>

    UserModify
  </coach-my-page-content-layout>
</template>

<script>
import CoachMyPageContentLayout from '@/views/layout/CoachMyPageContentLayout';

export default {
  name: 'UserModify',
  components: { CoachMyPageContentLayout },
};
</script>
